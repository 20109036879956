<template>
    <div
      class="content"
    >
    <div class="row justify-content-center " style="margin-top: 50px">
      <div class="" style="margin-top: 50px">
        <span style="font-size: 40px;">LOGO HERE</span>
      </div>
      <div class="col-md-12">
        <login-form :model="model" :login="login" :errorMessage="authError" :loading="loading">
        </login-form>
      </div>

  </div>
    </div>

</template>
<script>
  import LoginForm from './Login/LoginForm';
  import Auth from "../services/auth.js";
  import { mapGetters } from "vuex"

  export default {
    components: {
      LoginForm,
    },
    created() {
      console.log('----this', this.authError);
    },
    data() {
      return {
        model: {
          username: null,
          password: null
        },
        loading: false
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      bigLineChartCategories() {
        return this.$t('dashboard.chartCategories');
      },
      ...mapGetters([
        'authError',
      ])
    },
    methods: {
      login: async function() {
        if (!this.model.username || this.model.password.length < 8) return;
        try {
          this.$store.commit("setAuthError", null);
          this.loading = true;
          const response = await Auth.login({ username: this.model.username, password: this.model.password})
          console.log('---res,', response);
          console.log('---token,', response.data.data.token);
          Auth.setToken({access_token: response.data.data.token})
          this.$store.commit("isAuthenticated", { isAuthenticated: true });

          console.log('-------this', this.$store.getters.isAuthenticated);

          setTimeout(() => {
            console.log('-----replace');
            this.$router.replace("/");
          }, 1500);
          
        } catch (error) {
          console.log("error", error.response)
          this.$store.commit("setAuthError", error?.response?.data?.message);
        }
        this.loading = false

      }
    },
    mounted() {
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    }
  };
</script>
<style>
</style>
