<template>
  <div class="content">
    <div class="row justify-content-center " style="margin-top: 50px">
      <div class="" style="margin-top: 50px">
        <span style="font-size: 40px;">LOGO HERE</span>
      </div>
      <div class="col-md-12">
        <reset-password-form 
          :step="step" :model="model" :resetPassword="resetPassword" :errorMessage="authError"
          :loading="loading"
          :verify="verify"
          :updatePassword="updatePassword"
        >
        </reset-password-form>
      </div>

    </div>
  </div>

</template>
<script>
import ResetPasswordForm from './ResetPassword/ResetPasswordForm';
import Auth from "../services/auth.js";
import { mapGetters } from "vuex"

export default {
  components: {
    ResetPasswordForm,
  },
  created() {
    console.log('----this', this.authError);
  },
  data() {
    return {
      model: {
        email: null,
        code: null,
        password: null,
        confirmPassword: null
      },
      code: null,
      loading: false,
      step: 1
    }
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return this.$t('dashboard.chartCategories');
    },
    ...mapGetters([
      'authError',
    ])
  },
  methods: {
    resetPassword: async function () {
      if (!this.model.email) return;
      try {
        this.$store.commit("setAuthError", null);
        this.loading = true;
        const isSuccess = await this.$store.dispatch("resetPassword", { email: this.model.email })
        console.log('---isSuccess,', isSuccess);
        if (isSuccess) {
          this.successMessage = "Please enter the verification code sent to the given email";
          this.step = 2
        }


      } catch (error) {
        console.log("error", error.response)
        this.$store.commit("setAuthError", error?.response?.data?.message);
      }
      this.loading = false

    },
    verify: async function () {
      if (!this.model.email || !this.model.code) return;
      try {
        this.$store.commit("setAuthError", null);
        this.loading = true;
        const isSuccess = await this.$store.dispatch("verify", this.model)
        console.log('---isSuccess,', isSuccess);
        if (isSuccess) {
          this.step = 3
        }


      } catch (error) {
        console.log("error", error.response)
        this.$store.commit("setAuthError", error?.response?.data?.message);
      }
      this.loading = false
    },
    updatePassword: async function () {
      if (!this.model.email || !this.model.code || !this.model.password || this.model.password !== this.model.confirmPassword) return;
      try {
        this.$store.commit("setAuthError", null);
        this.loading = true;
        const isSuccess = await this.$store.dispatch("updatePassword", this.model)
        console.log('---isSuccess,', isSuccess);
        if (isSuccess) {
          this.step = 4
        }


      } catch (error) {
        console.log("error", error.response)
        this.$store.commit("setAuthError", error?.response?.data?.message);
      }
      this.loading = false
    },
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  }
};
</script>
<style>

</style>
