<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card class="login-form-card">
    <svg @click="$router.go(-1)" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle back-icon" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
    </svg>
    <h2 slot="header" class="title text-center">RESET PASSWORD</h2>

    <div v-if="step == 1" class="d-flex align-items-center justify-content-center">
      <div class="col-md-10 px-md-1">
        <base-input
          class="text-lg"
          label="Email"
          placeholder="Email"
          v-model="model.email"
        >
        </base-input>
        <h5 class="text-center text-danger mt-4" v-if="errorMessage">{{  errorMessage }}</h5>
      </div>
    </div>

    <div v-if="step == 2" class="d-flex align-items-center justify-content-center">
      <div class="col-md-10 px-md-1">
        <base-alert type="success" >
            <span class="text-center">Please enter the verification code sent to the given email</span>
          </base-alert>
        <base-input
          class="text-lg"
          label="Verification Code"
          placeholder="Verification Code"
          v-model="model.code"
        >
        </base-input>
        <h5 class="text-center text-danger mt-4" v-if="errorMessage">{{  errorMessage }}</h5>
      </div>
    </div>
    <div v-if="step == 3" class="d-flex align-items-center justify-content-center">
      <div class="col-md-10 px-md-1">
        <base-alert type="success" >
          <span class="text-center">Please enter your new password</span>
        </base-alert>
        <base-input
          class="text-lg"
          type="password"
          label="New Password"
          placeholder="New Password"
          v-model="model.password"
        >
        </base-input>
        <base-input
          class="text-lg"
        type="password"
          label="Confirm New Password"
          placeholder="Confirm New Password"
          v-model="model.confirmPassword"
        >
        </base-input>
        <h5 class="text-center text-danger mt-4" v-if="errorMessage">{{  errorMessage }}</h5>
      </div>
    </div>
    <div v-if="step == 4" class="d-flex align-items-center justify-content-center">
      <div class="col-md-10 px-md-1">
        <base-alert type="success" >
          <span class="text-center">Your password is successfully updated</span>
        </base-alert>
        <h5 class="text-center text-danger mt-4" v-if="errorMessage">{{  errorMessage }}</h5>
      </div>
    </div>

    <div class="mt-4 d-flex align-items-center justify-content-center">


      <base-button style="font-size: 17px;"  @click="submit" class="text-center" slot="footer" type="primary" fill>
        <clip-loader v-if="loading" color="white" size="20px" :loading="loading" ></clip-loader>
        <span v-else-if="step==2" >Verify</span>
        <span v-else-if="step==3" >Update Password</span>
        <span v-else-if="step==4" >Login</span>
        <span v-else>Send</span>
    </base-button>

    </div>
  </card>
</template>
<script>

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import { BaseAlert } from '@/components';


export default {
  components: {
    ClipLoader,
    BaseAlert
  },
  created() {
    console.log({model: this.model});
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    resetPassword: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    verify: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    updatePassword: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    errorMessage: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    }
  },
  methods: {
    submit() {
      if (this.step == 1) this.resetPassword();
      if (this.step == 2) this.verify()
      if (this.step == 3) this.updatePassword()
      if (this.step == 4) this.$router.replace("/login")
    }
  }
};
</script>
<style></style>
