<template>
  <div>
    <div class="row">
      <div class="col-lg-3" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.activeUsers')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-bell-55 text-primary "></i> 
              <clip-loader v-if="statsLoading" color="#C153EC" size="20px" :loading="statsLoading" style="display: inline;" ></clip-loader>
              <span v-else>
                {{ stats.total_users || 0}}
              </span>
            </h3>
          </template>
        </card>
      </div>
      <div class="col-lg-3" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.totalClicks')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-delivery-fast text-info "></i>
              
              <clip-loader v-if="statsLoading" color="#C153EC" size="20px" :loading="statsLoading" style="display: inline;" ></clip-loader>
              <span v-else>
                {{ stats.total_clicks || 0}}
              </span>
            </h3>
          </template>
        </card>
      </div>
      <div class="col-lg-3" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.totalConversions')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-send text-success "></i> 
              <clip-loader v-if="statsLoading" color="#C153EC" size="20px" :loading="statsLoading" style="display: inline;" ></clip-loader>
              <span v-else>
                {{ stats.total_conversions || 0}}
              </span>
            </h3>
          </template>
        </card>
      </div>
      <div class="col-lg-3" :class="{'text-right': isRTL}">
        <card type="chart">
          <template slot="header">
            <h5 class="card-category">{{$t('dashboard.totalOffers')}}</h5>
            <h3 class="card-title"><i class="tim-icons icon-send text-success "></i> 
              <clip-loader v-if="statsLoading" color="#C153EC" size="20px" :loading="statsLoading" style="display: inline;" ></clip-loader>
              <span v-else>
                {{ stats.total_offers || 0}}
              </span>  
            </h3>
          </template>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <!-- <h5 class="card-category">{{$t('dashboard.totalShipments')}}</h5> -->
                <h2 class="card-title">Activities</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle"
                     :class="isRTL ? 'float-left' : 'float-right'"
                     data-toggle="buttons">
                  <label v-for="(option, index) in bigLineChartCategories"
                         :key="option"
                         class="btn btn-sm btn-primary btn-simple"
                         :class="{active: purpleLineChart.activeIndex === index}"
                         :id="index">
                    <input type="radio"
                           @click="getReports(index)"
                           name="options" autocomplete="off"
                           :checked="purpleLineChart.activeIndex === index">
                    {{option}}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div class="chart-area">
            <div
              v-if="reportLoading"
              style="height: 100%"
              class="row justify-content-center align-items-center"
            >
              <clip-loader  color="#C153EC" size="40px" :loading="reportLoading" ></clip-loader>
            </div>
            <line-chart  :style="chartStyle"
                        ref="chart"
                        chart-id="purple-line-chart"
                        :chart-data="purpleLineChart.chartData"
                        :gradient-colors="purpleLineChart.gradientColors"
                        :gradient-stops="purpleLineChart.gradientStops"
                        :extra-options="purpleLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-12">
        <card type="tasks" :header-classes="{'text-right': isRTL}">
          <template slot="header">
            <h6 class="title d-inline">{{$t('dashboard.tasks', {count: 5})}}</h6>
            <p class="card-category d-inline">{{$t('dashboard.today')}}</p>
            <base-dropdown menu-on-right=""
                           tag="div"
                           title-classes="btn btn-link btn-icon"
                           aria-label="Settings menu"
                           :class="{'float-left': isRTL}">
              <i slot="title" class="tim-icons icon-settings-gear-63"></i>
              <a class="dropdown-item" href="#pablo">{{$t('dashboard.dropdown.action')}}</a>
              <a class="dropdown-item" href="#pablo">{{$t('dashboard.dropdown.anotherAction')}}</a>
              <a class="dropdown-item" href="#pablo">{{$t('dashboard.dropdown.somethingElse')}}</a>
            </base-dropdown>
          </template>
          <div class="table-full-width table-responsive">
            <task-list></task-list>
          </div>
        </card>
      </div>
      <div class="col-lg-6 col-md-12">
        <card class="card" :header-classes="{'text-right': isRTL}">
          <h4 slot="header" class="card-title">{{$t('dashboard.simpleTable')}}</h4>
          <div class="table-responsive">
            <user-table></user-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import * as chartConfigs from '@/components/Charts/config';
  import TaskList from './Dashboard/TaskList';
  import UserTable from './Dashboard/UserTable';
  import config from '@/config';
  import { mapGetters } from 'vuex'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

  export default {
    components: {
      LineChart,
      BarChart,
      TaskList,
      UserTable,
      ClipLoader
    },
    data() {
      return {
        bigLineChart: {
          allData: [
            [100, 70, 90, 70, 85, 60, 75, 60, 90, 80, 110, 100],
            [80, 120, 105, 110, 95, 105, 90, 100, 80, 95, 70, 120],
            [60, 80, 65, 130, 80, 105, 90, 130, 70, 115, 60, 130]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [{ }],
            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
          },
          extraOptions: chartConfigs.purpleChartOptions,
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
          categories: []
        },
        purpleLineChart: {
          extraOptions: chartConfigs.purpleChartOptions,
          activeIndex: 0,
          chartData: {
            labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            datasets: [
              {
                label: "Clicks",
                fill: true,
                borderColor: config.colors.primary,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.primary,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.primary,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: [80, 100, 70, 80, 120, 80],
              },
              {
                label: "Conversions",
                fill: true,
                borderColor: config.colors.danger,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: 'rgba(201, 36, 36, 0)',
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: [50, 10, 40, 84, 20, 40],
              },
            ]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.2, 0],
          activeIndex: 0,
        },
        greenLineChart: {
          extraOptions: chartConfigs.greenChartOptions,
          chartData: {
            labels: ['JUL', 'AUG', 'SEP', 'OCT', 'NOV'],
            datasets: [{
              label: "My First dataset",
              fill: true,
              borderColor: config.colors.danger,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.danger,
              pointBorderColor: 'rgba(255,255,255,0)',
              pointHoverBackgroundColor: config.colors.danger,
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [90, 27, 60, 12, 80],
            }]
          },
          gradientColors: ['rgba(66,134,121,0.15)', 'rgba(66,134,121,0.0)', 'rgba(66,134,121,0)'],
          gradientStops: [1, 0.4, 0],
        },
        blueBarChart: {
          extraOptions: chartConfigs.barChartOptions,
          chartData: {
            labels: ['USA', 'GER', 'AUS', 'UK', 'RO', 'BR'],
            datasets: [{
              label: "Countries",
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [53, 20, 10, 80, 100, 45],
            }]
          },
          gradientColors: config.colors.primaryGradient,
          gradientStops: [1, 0.4, 0],
        },
        reportLoading: false,
        statsLoading: false
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      bigLineChartCategories() {
        return this.$t('dashboard.chartCategories');
      },
      chartStyle() {
        return {height: '100%', display: this.reportLoading ? 'none' : undefined}
      },
      ...mapGetters([
        'stats',
        'reports'
      ])
    },
    methods: {

      async getDailyStats(includes = ["users", "clicks", "conversions", "offers"]) {
        console.log('---this.getDailyStats');
        this.statsLoading = true;
        await this.$store.dispatch("getDailyStats", {includes});
        this.statsLoading = false;
      },
      async getReports(index) {
        const type = index == 0 ? 'day' : 'week'
        this.purpleLineChart.activeIndex = index
        console.log('---this.getDailyStats', index);
        this.reportLoading = true;
        await this.$store.dispatch("getReports", {type});
        this.reportLoading = false;
      }
    },
    mounted() {
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
      console.log('----mounted');
      this.getDailyStats()
      this.getReports(0)
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    },
    watch: {
      reports(val) {
        
        console.log('---reports changed', val.clicks.map(x => x._id));
        const chartData = {
            labels: val.clicks.map(x => x._id.toString()),
            datasets: [
              {
                label: "Clicks",
                fill: true,
                borderColor: config.colors.primary,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.primary,
                pointBorderColor: 'rgba(255,255,255,0)',
                pointHoverBackgroundColor: config.colors.primary,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: val.clicks.map(x => x.amount),
              },
              {
                label: "Conversions",
                fill: true,
                borderColor: config.colors.danger,
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: config.colors.danger,
                pointBorderColor: 'rgba(201, 36, 36, 0)',
                pointHoverBackgroundColor: config.colors.danger,
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
                data: val.conversions.map(x => x.amount),
              },
            ]
          }

          console.log('---this.$refs', this.$refs.chart);
        this.$refs.chart.updateGradients(chartData);
        this.purpleLineChart.chartData = chartData;

        // this.purpleLineChart.chartData.datasets[1].data = val.conversions.map(x => x.amount)
        // this.purpleLineChart.chartData.datasets[1].label = val.conversions.map(x => x._id.toString())
        console.log('------this.purpleLineChart', this.purpleLineChart);
      }
    }
  };
</script>
<style>
</style>
