<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card class="login-form-card">
    <h2 slot="header" class="title text-center">LOGIN</h2>
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-md-10 px-md-1" style="font-size: 18px;">
        <base-input
          class="text-lg"
          label="Username"
          placeholder="Username"
          v-model="model.username"
        >
        </base-input>
        <base-input
          class="text-lg"
          type="password"
          label="Password"
          placeholder="Password"
          v-model="model.password"
        >
        </base-input>
        <div
          style="display: flex; justify-content: space-between; margin-top: -5px;"
        >
          <small cl @click="toRegister()" role="button" style="cursor: pointer; font-size: 0.8rem;"><u>Create Account</u></small>
          <small @click="toResetPassword()" role="button" style="cursor: pointer; font-size: 0.8rem;"><u>Reset Password</u></small>
          
        </div>

        <h5 class="text-center text-danger mt-4 text-error" v-if="errorMessage">{{  errorMessage }}</h5>
      </div>
  </div>

    <div class="mt-4 d-flex align-items-center justify-content-center">
      <base-button  @click="login" class="text-center" slot="footer" type="primary" fill>
        <clip-loader v-if="loading" color="white" size="20px" :loading="loading" ></clip-loader>
        <span v-else style="font-size: 16px;">Login</span>
    </base-button>

    </div>
  </card>
</template>
<script>

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    ClipLoader
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    login: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    errorMessage: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toRegister() {
      this.$store.commit("setAuthError", null);
      this.$router.replace("/register")
    },
    toResetPassword() {
      this.$store.commit("setAuthError", null);
      this.$router.replace("/reset-password")
    }
  }
};
</script>
<style></style>
