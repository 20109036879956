<template>
    <div
      class="content"
    >
    <div class="row justify-content-center " style="margin-top: 50px">
      <div class="" style="margin-top: 50px">
        <span style="font-size: 40px;">LOGO HERE</span>
      </div>
      <div class="col-md-12">
        <register-form :model="model" :register="register" :errorMessage="authError" :loading="loading">
        </register-form>
      </div>

  </div>
    </div>

</template>
<script>
  import RegisterForm from './Register/RegisterForm';
  import Auth from "../services/auth.js";
  import { mapGetters } from "vuex"

  export default {
    components: {
      RegisterForm,
    },
    created() {
      console.log('----this', this.authError);
    },
    data() {
      return {
        model: {
          username: null,
          password: null,
          email: null,
          confirmPassword: null
        },
        loading: false
      }
    },
    computed: {
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      bigLineChartCategories() {
        return this.$t('dashboard.chartCategories');
      },
      ...mapGetters([
        'authError',
      ])
    },
    methods: {
      register: async function() {
        if (!this.model.username) {
           this.$store.commit("setAuthError", "Username is required");
          return;
        }
           if (!this.model.email) {
           this.$store.commit("setAuthError", "Email is required");
            return;
        }
           if (!this.isValidEmail(this.model.email)) {
           this.$store.commit("setAuthError", "Email is not valid");
            return;
        }
           if (this.model.password?.length < 8) {
           this.$store.commit("setAuthError", "Passowrd must contain minimum 8 characters");
            return;
        }
           if (this.model.confirmPassword !== this.model.password) {
           this.$store.commit("setAuthError", "Password and confirmation password do not match");
            return;
        }
           try {
          this.$store.commit("setAuthError", null);
          this.loading = true;
          const response = await Auth.register(this.model)
          console.log('---res,', response);
          console.log('---token,', response.data.data.token);
          Auth.setToken({access_token: response.data.data.token})
          this.$store.commit("isAuthenticated", { isAuthenticated: true });

          console.log('-------this', this.$store.getters.isAuthenticated);

          setTimeout(() => {
            console.log('-----replace');
            this.$router.replace("/");
          }, 1500);
          
        } catch (error) {
          console.log("error", error.response)
          this.$store.commit("setAuthError", error?.response?.data?.message);
        }
        this.loading = false

      },
      isValidEmail(email) {
          const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return re.test(String(email).toLowerCase())
      }
    },
    mounted() {
      this.i18n = this.$i18n;
      if (this.enableRTL) {
        this.i18n.locale = 'ar';
        this.$rtl.enableRTL();
      }
    },
    beforeDestroy() {
      if (this.$rtl.isRTL) {
        this.i18n.locale = 'en';
        this.$rtl.disableRTL();
      }
    }
  };
</script>
<style>
</style>
